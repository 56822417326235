import React, { useCallback, useEffect } from 'react';
import PageContainer from 'src/pages/components/page-container';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { createResponse, getResponses } from 'src/store/survey';
import { help_desk_url, registered_survey_id } from 'src/services/config';
import { getUserAccountData } from 'src/store/user';
import { getUserId } from 'src/services/storage';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Loader from 'src/components/basic/loader';

export default function SurveyWelcome() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { accountData, process_inprogress, is_logged_in, is_success } =
    useSelector((state: any) => state.user);
  useEffect(() => {
    if (is_logged_in) {
      dispatch(getUserAccountData());
    } else {
      navigate('/login');
    }
  }, []);
  useEffect(() => {
    if (!process_inprogress && !is_success) {
      if (Object.keys(accountData).length == 0) {
        navigate('/profile');
      }
    }
  }, [accountData, process_inprogress]);
  const { response_list, response, is_response_created } = useSelector(
    (state: any) => state.survey,
  );
  const is_survey_loading = useSelector(
    (state: any) => state.survey.is_loading,
  );

  useEffect(() => {
    loadResponse();
  }, []);

  useEffect(() => {
    if (is_response_created) {
      goRegisterSurvey();
    }
  }, [is_response_created]);

  const loadResponse = () => {
    dispatch(
      getResponses({
        survey_id: registered_survey_id,
        user_id: null,
        status: 'DRAFT',
      }),
    );
  };

  const hasResponseDraft = useCallback(() => {
    return response_list.length > 0 || response;
  }, [response_list, response]);

  const getButtonTitle = useCallback(() => {
    return hasResponseDraft()
      ? t('survey_welcome.continue_risk_assessment')
      : t('survey_welcome.start_risk_assessment');
  }, [hasResponseDraft]);

  const actionRiskAssessment = useCallback(() => {
    if (hasResponseDraft()) {
      goRegisterSurvey();
    } else {
      makeNewResponse();
    }
  }, [hasResponseDraft]);

  const makeNewResponse = () => {
    dispatch(
      createResponse({
        survey_id: registered_survey_id,
        user_id: getUserId(),
      }),
    );
  };

  const goRegisterSurvey = () => {
    navigate('/registered-survey');
  };

  return (
    <PageContainer>
      {is_survey_loading && <Loader />}
      <div className="mx-auto py-2 max-w-screen text-black text-xl">
        <div className="font-bold text-3xl">{t('survey_welcome.title')}</div>
        <div className="my-5 py-4">{t('survey_welcome.welcome')}</div>
        <div className="py-4">
          <Trans i18nKey="survey_welcome.description_1"></Trans>
        </div>
        <div className="py-4 font-bold">
          <Trans i18nKey="survey_welcome.description_2">
            Please note that you will not be able to submit the Feedlot Risk
            Assessment for evaluation by an Ausvet consultant until
            <span className="underline">all</span>
            questions have been completed
          </Trans>
        </div>
        <div className="py-4">{t('survey_welcome.description_3')}</div>
        <div className="py-4">{t('survey_welcome.description_4')}</div>
        <div className="py-4">{t('survey_welcome.description_5')}</div>
        <div className="py-4">
          <Trans i18nKey="survey_welcome.description_6">
            Submit any comments, questions or suggestions related to the Risk
            Assessment
            <a
              href={help_desk_url}
              className="text-accent underline"
              target="_rel">
              here
            </a>
          </Trans>
        </div>
        <div className="flex justify-end">
          <button
            className="btn btn-primary text-white"
            onClick={actionRiskAssessment}>
            {getButtonTitle()}
          </button>
        </div>
      </div>
    </PageContainer>
  );
}
