import { CustomValidator } from 'reactjs-forms/types';
import { store } from 'src/store';
import {
  PASSWORD_MIN_LEN,
  digitRegex,
  emailRegex,
  lowercaseRegex,
  specialLetterRegex,
  uppercaseRegex,
} from './config';
import { t } from 'i18next';

export function updateSelectedSectionId(section_id: number) {
  store.dispatch({
    type: 'survey/updateSelectedSectionId',
    payload: section_id,
  });
}

export function setSurveyType(survey_type: string | undefined) {
  store.dispatch({
    type: 'survey/setSurveyType',
    payload: survey_type,
  });
}

export function setHighlightUnanswered(highlight_unanswered: boolean){
  store.dispatch({
    type: 'survey/setHighlightUnanswered',
    payload: highlight_unanswered,
  });
}

export function setAnswer(
  section_id: number,
  question_id: number,
  answer: string,
) {
  store.dispatch({
    type: 'survey/setAnswer',
    payload: {
      section_id,
      question_id,
      answer,
    },
  });
}

export function clearAnswers(category_id: number) {
  store.dispatch({
    type: 'survey/clearAnswers',
    payload: category_id,
  });
}

export function setLoading(is_loading: boolean) {
  store.dispatch({
    type: 'extra/setLoading',
    payload: is_loading,
  });
}

export function setUserLoggedIn(is_logged_in: boolean) {
  store.dispatch({
    type: 'user/setIsLoggedIn',
    payload: is_logged_in,
  });
}

export function setUserLoggedOut(is_logged_out: boolean) {
  store.dispatch({
    type: 'user/setUserLoggedOut',
    payload: is_logged_out,
  });
}

export function setIsPasswordUpdate(is_password_updated: boolean) {
  store.dispatch({
    type: 'user/setIsPasswordUpdate',
    payload: is_password_updated,
  });
}

export function setUserSuccess(is_success: boolean) {
  store.dispatch({
    type: 'user/setIsSuccess',
    payload: is_success,
  });
}

export function setShowNavBar(show_navbar: boolean) {
  store.dispatch({
    type: 'extra/setShowNavBar',
    payload: show_navbar,
  });
}

type TypeEmail = (name: string, email: string) => CustomValidator;

export const isValidEmail: TypeEmail = (label, email) => {
  return function (value: string | string[], identity: string) {
    let result = email != undefined && identity != undefined;
    let msg = '';

    if (!email || email.length < 1) {
      result = false;
      msg = label + t('is_required');
    } else if (!emailRegex.test(email)) {
      result = false;
      msg = t('email_invalid');
    } else {
      result = true;
      msg = '';
    }

    return {
      msg: msg,
      result: result,
    };
  };
};

type TypeName = (name: string) => CustomValidator;

export const isValidName: TypeName = (label) => {
  return function (value: string | string[], identity: string) {
    let result = value != undefined && identity != undefined;
    let msg = '';

    if (!value || value.length < 1) {
      result = false;
      msg = label + t('is_required');
    } else {
      result = true;
      msg = '';
    }

    return {
      msg: msg,
      result: result,
    };
  };
};

type TypePassword = (password: string) => CustomValidator;

export const isValidPassword: TypePassword = (password) => {
  return function (value: string | string[], identity: string) {
    let result = password != undefined && identity != undefined;
    let msg = '';

    if (!password || password.length < 1) {
      result = false;
      msg = t('password_validation.required');
    } else if (password.length < PASSWORD_MIN_LEN) {
      result = false;
      msg = t('password_validation.min_len');
    } else if (!lowercaseRegex.test(password)) {
      result = false;
      msg = t('password_validation.contain_lowercase');
    } else if (!uppercaseRegex.test(password)) {
      result = false;
      msg = t('password_validation.contain_capital');
    } else if (!digitRegex.test(password)) {
      result = false;
      msg = t('password_validation.contain_digit');
    } else if (!specialLetterRegex.test(password)) {
      result = false;
      msg = t('password_validation.contain_special');
    } else {
      result = true;
      msg = '';
    }

    return {
      msg: msg,
      result: result,
    };
  };
};

type TypeIsEqulPasswords = (password: string) => CustomValidator;

export const isEqulPasswords: TypeIsEqulPasswords = (password) => {
  return function (value: string | string[], identity: string) {
    return {
      msg: t('password_validation.password_not_match'),
      result: password === value && identity != undefined,
    };
  };
};

export const _isValidPassword = (password: string) => {
  let result = false;

  if (!password || password.length < 1) {
    result = false;
  } else if (password.length < PASSWORD_MIN_LEN) {
    result = false;
  } else if (!lowercaseRegex.test(password)) {
    result = false;
  } else if (!uppercaseRegex.test(password)) {
    result = false;
  } else if (!digitRegex.test(password)) {
    result = false;
  } else if (!specialLetterRegex.test(password)) {
    result = false;
  } else {
    result = true;
  }

  return result;
};

export const _isEqualPasswords = (
  password: string,
  password_confirm: string,
) => {
  return password === password_confirm;
};
